import {APIActionService, CommonAPIActionProps} from 'services';

export class CRMAPIService extends APIActionService {
    constructor(props: CommonAPIActionProps) {
        const {path, ...rest} = props;
        const realPath = path ? `${path}` : '';
        super({
            path: `crm/${realPath}`,
            ...rest
        });
    }
}