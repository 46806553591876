import {KeyValueObject} from 'types';
import {CollectionEventCallback, CollectionEventEnum, CollectionEventsHandler, CollectionTriggerEnum} from 'utils/Collection/CollectionEventsHandler';
import {Is} from 'utils/Is';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

export interface CollectionItemProps {

    itemFormatter?: ObjectFormatterPropsType; //will be used in replace item
    //events
    afterEdit?: CollectionEventCallback;
    dirtyChange?: CollectionEventCallback;
}

//https://blog.simontest.net/extend-array-with-typescript-965cc1134b3 worth to checkout
export class CollectionItem {
    private item: KeyValueObject;
    private isItemDirty: boolean;
    protected events!: CollectionEventsHandler;
    protected itemFormatter = new ObjectFormatter();


    constructor(item: KeyValueObject, props?: CollectionItemProps) {
        props?.itemFormatter && this.itemFormatter.add(props.itemFormatter);
        this.item = item;
        this.isItemDirty = false;
        this.events = new CollectionEventsHandler(props);
    }

    of(field: string): CollectionItem {
        return new CollectionItem(this.get(field, {}));
    }

    exists(field: string): boolean {
        if (this.item === null) {
            return false;
        }
        return Object.keys(this.item).indexOf(field) >= 0;
    }

    //region dirty
    dirty(): boolean {
        return this.isItemDirty;
    }

    private setIsDirty(is: boolean, fireEvents: boolean = true) {
        this.isItemDirty = is;
        if (fireEvents) {
            this.events.fire(
                CollectionEventEnum.dirtyChange,
                CollectionTriggerEnum.setIsDirty,
                {is}
            );
        }
    }

    isDirty(): boolean {
        return this.isItemDirty;
    }

    //endregion

    replace(item: KeyValueObject, voidEvents: boolean = false): this {
        this.setIsDirty(!Is.equal(this.item, item));
        this.item = this.itemFormatter.fire(item);
        if (!voidEvents) {
            this.events.fire(
                CollectionEventEnum.afterEdit,
                CollectionTriggerEnum.replace,
                {row: item}
            );
        }
        return this;
    }

    editValue(field: string, value: any, voidEvents: boolean = false): this {
        const currentValue = this.get(field, null);
        this.setIsDirty(!Is.equal(currentValue, value));
        this.item[field] = value;
        if (!voidEvents) {
            this.events.fire(
                CollectionEventEnum.afterEdit,
                CollectionTriggerEnum.editValue,
                {row: this.item}
            );
        }
        return this;
    }

    get<F extends string, D = null>(field: F, defaults: D = (null as unknown) as D): any|D {
        if (!this.exists(field)) {
            return defaults;
        }
        return this.item[field];
    }

    getArray(field: string): any[] {
        return this.get(field, []);
    }

    getId(defaultValue: any = null): number {
        return this.get('ID', defaultValue);
    }

    getItem(clone: boolean = false): KeyValueObject {
        if (clone) {
            return {...this.item};
        }
        return this.item;
    }

    logConsole(name: string = 'collectionItem'): void {
        console.log(name, this.getItem());
    }

    empty(): boolean {
        return Object.keys(this.item).length <= 0;
    }

    notEmpty(): boolean {
        return !this.empty();
    }

    isEmpty(field?: string): boolean {
        if (field) {
            if (!this.exists(field)) {
                return true;
            }
            return Is.empty(this.get(field), true);
        }
        return !Object.keys(this.item).some(f => !this.isEmpty(f));
    }

    is(field: string, checkValue: any): boolean {
        return this.get(field, null) === checkValue;
    }

    isOneOf(field: string, checkValue: any[]): boolean {
        return checkValue.includes(this.get(field, undefined));
    }

    isNotEmpty(field?: string): boolean {
        return !this.isEmpty(field);
    }

    each(callback: (value: any, field: string) => void) {
        for (const field in this.item) {
            callback(this.item[field], field);
        }
    }

    some(callback: (value: any, field: string) => boolean): boolean {
        for (const field in this.item) {
            if (callback(this.item[field], field)) {
                return true;
            }
        }
        return false;
    }

    filter(callback: (value: any, field: string) => boolean): any[] {
        const items = [];
        for (const field in this.item) {
            if (callback(this.item[field], field)) {
                items.push(this.item[field]);
            }
        }
        return items;
    }

    //region events

    dirtyChange(caller: CollectionEventCallback): void {
        this.events.add(CollectionEventEnum.dirtyChange, caller);
    }

    afterEdit(caller: CollectionEventCallback): void {
        this.events.add(CollectionEventEnum.afterEdit, caller);
    }

    //endregion
}
