import * as React from 'react';
import Modal from 'components/modals/Modal/Modal';
import {gsm} from "utils/globalStateManager";
import * as Styled from './ErrorModal.styled';

const ErrorModal: React.FC = () => {
    const error = gsm.getError();

    const closeModal = () => {
        gsm.setError({
            state: false,
            title: '',
            content: ''
        });
    };

    return (
        <Modal
            isOpen={error.state}
            onRequestClose={closeModal}
            overlayCSSProps={{
                zIndex: 999999
            }}
        >
            <Styled.Wrapper>
                <Styled.CloseButton onClick={closeModal}>
                    <Styled.CloseModalIcon/>
                </Styled.CloseButton>
                {error.title && <Styled.Title>{error.title}</Styled.Title>}
                {error.content && <Styled.Description html={error.content}/>}
            </Styled.Wrapper>
        </Modal>
    );
};

export default ErrorModal;
