import DangerousInnerHTML from "components/DangerousInnerHTML/DangerousInnerHTML";
import mediaQueries       from 'constants/mediaQueries';
import styled             from 'styled-components';
import CloseModal         from 'components/icons/CloseModal';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 660px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0 4px 35px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  padding: 50px 50px 30px;

  @media ${mediaQueries.mobileOrTablet} {
    width: calc(100% - 40px);
    padding: 0;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  width: fit-content;
  background: transparent;
  border: none;
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
  
  @media ${mediaQueries.mobileOrTablet} {
    top: 20px;
    right: 20px;
  }
`;

export const CloseModalIcon = styled(CloseModal)``;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #3C5168;
  margin-bottom: 35px;
  
  @media ${mediaQueries.mobileOrTablet} {
    font-size: 24px;
    line-height: 31px;
    padding: 43px 30px 0;
    margin-bottom: 0;
  }
`;

export const Description = styled(DangerousInnerHTML)`
  font-size: 18px;
  line-height: 24px;
  color: #3C5168;
  margin-bottom: 50px;
  
  @media ${mediaQueries.mobileOrTablet} {
    margin-bottom: 0;
    margin-top: 30px;
    padding: 0 30px;
    font-size: 15px;
    line-height: 20px;
  }
`;
