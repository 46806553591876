import {toNumber} from 'lodash';
import moment from 'moment/moment';
import {KeyValueObject} from 'types';
import {gsm} from 'utils/globalStateManager';

export class Globals {

    static routeParams = {} as KeyValueObject;

    static useParams(params: KeyValueObject) {
        Globals.routeParams = params;
    }

    static getCompanyId(): number {
        return Globals.routeParams.CID ? toNumber(Globals.routeParams.CID) : 9999;
    }

    static activateLoading() {
        gsm.setLoading(true);
    }

    static disableLoading() {
        gsm.setLoading(false);
    }

    static getFieldsParents() {
        return [
            {value: 7, label: 'Ehitus'},
            {value: 82, label: 'Ehitusmaterjalid'},
        ];
    }

    static getGeneralDateRanges() {
        return {
            'last12months': {
                from: moment().subtract(12, 'months').startOf('month').format(),
                to: moment().format(),
            },
            'last6months': {
                from: moment().subtract(6, 'months').format(),
                to: moment().format(),
            },
            'last3months': {
                value: '3months',
                from: moment().subtract(3, 'months').startOf('month').format(),
                to: moment().format(),
            },
            'lastMonth': {
                value: 'lastMonth',
                from: moment().subtract(1, 'month').startOf('month').format(),
                to: moment().subtract(1, 'month').endOf('month').format(),
            },
            'currentMonth': {
                from: moment().startOf('month').format(),
                to: moment().endOf('month').format(),
            },
            'yesterday': {
                from: moment().subtract(1, 'day').format(),
                to: moment().subtract(1, 'day').format(),
            },
            'today': {
                from: moment().format(),
                to: moment().format(),
            },
        };
    }

    static getGeneralDateRangeOptions() {
        return [
            {value: 'last12months', label: '1 aasta'},
            {value: 'last6months', label: '6 kuud'},
            {value: 'last3months', label: '3 kuud'},
            {value: 'lastMonth', label: 'viimane täiskuu'},
            {value: 'currentMonth', label: 'käesolev kuu'},
            {value: 'yesterday', label: 'eile'},
            {value: 'today', label: 'täna'}
        ];
    }
}

