import {APIService} from 'services';
import {CompanyInfo, StatisticsGraphData} from 'types';

export class CRMCompanyService extends APIService {

    constructor(companyId: number) {
        super({
            path: `/crm/company/${companyId}`
        });
    }

    getCompany(): Promise<CompanyInfo> {
        return this.get('', '');
    }

    getPaymentURL(invoiceId: number, paymentMethod: string): Promise<StatisticsGraphData> {
        return super.get(`/crm/company/invoices/getPaymentURL/${invoiceId.toString()}/${paymentMethod}`);
    }
}