import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  width: 320px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.08);
`;

export const LoadingTitle = styled.h2`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #3C5168;
  text-align: center;
`;

export const LoadingSpinner = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  margin: 10px auto;
  align-self: center;
  justify-content: center;
`;

export const LoadingStatus = styled.div`
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  color: #3C5168;
`;
