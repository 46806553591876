import {State, StateValue} from 'utils/StateManager/types';

export class StateManager<T extends StateValue> {
    protected state!: State;

    constructor(state: State) {
        this.state = state;
    }

    get value(): T {
        return this.state[0];
    }

    set value(value: T) {
        this.state[0] = value;
    }

    get<K extends keyof T>(name: K): T[K] {
        return this.value[name];
    }

    set<K extends keyof T>(name: K|Partial<T>, value?: T[K]): this;
    set(name: keyof T|Partial<T>, value?: any): this {
        if (typeof name === "string") {
            this.state[0][name] = value;
        }
        else {
            const obj = name as T;
            for (const i in obj) {
                this.state[0][i] = obj[i];
            }
        }
        return this;
    }

    setData(value: T["data"]): this {
        return this.set("data", value);
    }

    /**
     * fires React.SetStateAction with current state value
     */
    dispatch(event: string|null = 'dispatch') {
        console.log(`dispatch: ${event}`);
        this.state[1]({...this.value, event: event});
    }

    /**
     * get last dispatch event
     */
    get event(): string|null {
        return this.value.event ?? 'init';
    }

    static createStateValue<ST extends StateValue>(value: ST): ST {
        if (!value.event) {
            value.event = 'init';
        }
        return value;
    }
}

export default StateManager;