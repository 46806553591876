//import $                 from 'jquery'
import * as Styled from 'components/modals/Modal/Modal.styled';
import {ReactModalProps} from 'components/modals/Modal/Modal.styled';
import * as React from 'react';
import {CSSProperties} from 'react';

interface OwnProps extends ReactModalProps {
    showClose?: boolean;
    title?: string;
    width?: string|number;
    marginTop?: number;
    contentCSSProps?: CSSProperties;
    overlayCSSProps?: CSSProperties;
}

const Modal: React.FC<OwnProps> = (props) => {
    let contentCSS = '';
    const reactToCSS = require('react-style-object-to-css');
    if (props.contentCSS) {
        contentCSS = props.contentCSS.trim();
        if (contentCSS.charAt(contentCSS.length - 1) !== ';') {
            contentCSS += ';';
        }
    }
    contentCSS += reactToCSS(
        Object.assign(
            {
                zIndex: 55,
                top: (props.marginTop ?? 10) + 'vh',
                left: '50%',
                transform: 'translate(-50%,0)',
            },
            props.contentCSSProps ?? {}
        )
    );

    let overlayCSS = '';
    if (props.overlayCSS) {
        overlayCSS = props.overlayCSS.trim();
        if (overlayCSS.charAt(overlayCSS.length - 1) !== ';') {
            overlayCSS += ';';
        }
    }
    overlayCSS += reactToCSS(
        Object.assign(
            {zIndex: 900} as CSSProperties,
            props.overlayCSSProps ?? {}
        )
    );

    return (
        <Styled.ReactModalWrapper
            ariaHideApp={false}
            contentCSS={contentCSS}
            overlayCSS={overlayCSS}
            overlayClassName={'Overlay'}
            modalClassName={'Modal'}
            parentSelector={() => document.querySelector('#root') as HTMLElement}
            {...props}
            // data={{
            //   omt: scrollTop
            // }}
        >
            <Styled.Wrapper className={'modal-inner-wrapper'} style={{width: props.width ?? 'auto'}}>
                {props.showClose &&
                    <Styled.CloseButton onClick={(e) => props.onRequestClose && props.onRequestClose(e)}>
                        <Styled.CloseModalIcon/>
                    </Styled.CloseButton>
                }
                {props.title &&
                    <Styled.Header className={'modal-title-wrapper'}>
                        <Styled.Title>{props.title}</Styled.Title>
                    </Styled.Header>
                }
                <div className="modal-content-wrapper">
                    {props.children}
                </div>
            </Styled.Wrapper>
        </Styled.ReactModalWrapper>
    );
};

export default Modal;
