import _ from 'lodash';
import React from "react";
import {CompanyInfo} from 'types';


export interface ErrorState extends Object {
    state: boolean;
    title: string;
    content: string;
}

interface States {
    error: [ErrorState, React.Dispatch<React.SetStateAction<ErrorState>>];
    showLoading: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    companyInfo: [CompanyInfo, React.Dispatch<React.SetStateAction<CompanyInfo>>];
    // error: [ErrorState, (value: (prevState: ErrorState) => ErrorState) => void];
    // showLoading: [boolean, (value: (prevState: boolean) => boolean) => void];
}

type StateKeys = keyof States;

class GlobalStateManager {
    private useStateF!: <S>(initialState: ((() => S)|S)) => [S, React.Dispatch<React.SetStateAction<S>>];
    protected states: States = {} as States;

    withUseState(useState: <S>(initialState: S|(() => S)) => [S, React.Dispatch<React.SetStateAction<S>>]): this {
        this.useStateF = useState;
        return this;
    }

    init() {
        this.states = {
            error: this.useStateF({
                state: false,
                title: '',
                content: ''
            } as ErrorState),
            showLoading: this.useStateF(false),
            companyInfo: this.useStateF({ID: 0} as CompanyInfo)
        };
    }

    refresh<K extends keyof States>(name?: K): this {
        if (name) {
            this.states[name] = _.clone(this.states[name]);
        }
        else {
            Object.keys(this.states).forEach((key: keyof States) => this.refresh(key));
        }

        return this;
    }

    get<K extends keyof States>(name: K): States[K][0] {
        return this.states[name][0];
    }

    set<K extends StateKeys>(name: K, payload: States[K][0]): this;
    set(name: StateKeys, payload: any): this {
        const value = payload;
        this.states[name][0] = value;
        this.states[name][1](value);
        return this;
    }

    getError(): ErrorState {
        return this.get('error');
    }

    setError(state: ErrorState): this {
        this.set('error', state);
        return this;
    }

    isLoading(): boolean {
        return this.get('showLoading');
    }

    setLoading(state: boolean): this {
        this.set('showLoading', state);
        return this;
    }

    get company(): CompanyInfo {
        return this.get('companyInfo');
    }

    setCompanyInfo(state: CompanyInfo): this {
        this.set('companyInfo', state);
        return this;
    }
}

export const gsm = new GlobalStateManager();


