import _ from 'lodash';

import {KeyValueObject} from 'types';
import {Is} from 'utils/Is';

export class Arr {

    static removeByFieldValue<A extends KeyValueObject[], F extends keyof A[0]>(array: A, field: F, value: any): void {
        array = _.remove(array, item => {
            // @ts-ignore
            return item[field] === value;
        }) as A;
    }


    static removeByValue<A extends any[]>(array: A, value: any): void {
        array = _.remove(array, av => av === value) as A;
    }

    static pushWhenNotIncludes<A extends any[]>(array: A, ...value: any): void {
        value.forEach((v: any) => {
            if (!array.includes(v)) {
                array.push(v);
            }
        });
    }

    static toArray<A extends any>(value: A|A[]): A[] {
        return Array.isArray(value) ? value : [value];
    }

    static put(current: any, key: string, value: any) {

        const keys = key.split('.');
        for (let i = 0; i < keys.length - 1; i++) {
            let currentKey: number|string = keys[i];
            if (Is.integerLike(currentKey)) {
                currentKey = parseInt(currentKey);
            }
            if (current[currentKey] === undefined) {
                current[currentKey] = {};
            }
            current = current[currentKey];
        }
        const finalKey = keys[keys.length - 1];
        current[finalKey] = value;
    }

    static get(current: any, key: string, defaults: any = null): any {
        const keys = key.split('.');
        for (const k of keys) {
            if (!current) {
                return defaults;
            }
            if (Array.isArray(current) && parseInt(k) < current.length) {
                current = current[parseInt(k)];
            }
            else if (current.hasOwnProperty(k)) {
                current = current[k];
            }
            else {
                return defaults; // Key doesn't exist
            }
        }

        return current;
    }
}

