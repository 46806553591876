import {Active, Over} from '@dnd-kit/core/dist/store';
import {DragEndEvent} from '@dnd-kit/core/dist/types';
import {arrayMove} from '@dnd-kit/sortable';
import {toNumber} from 'lodash';
import {KeyValueObject, KeyValueValueObject} from 'types';
import {Arr} from 'utils/Arr';
import {CollectionEventCallback, CollectionEventEnum, CollectionEventPropertyType, CollectionEventsHandler, CollectionItem, CollectionTriggerEnum} from 'utils/Collection';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';

export interface CollectionOptions {
    itemFormatter?: ObjectFormatterPropsType; //used just before row is added/set

    //events
    afterEdit?: CollectionEventCallback;
    afterDelete?: CollectionEventCallback;
    afterOrder?: CollectionEventCallback;
}

export interface CollectionProps extends CollectionOptions {
    items: KeyValueObject[];
}

export class Collection {
    protected itemFormatter = new ObjectFormatter();
    protected events: CollectionEventsHandler;
    private items: CollectionItem[];
    private isItemsDirty: boolean;

    constructor(props: CollectionProps) {
        props.itemFormatter && this.itemFormatter.add(props.itemFormatter);
        this.items = [...props.items].map(item => new CollectionItem(this.itemFormatter.fire(item)));
        this.events = new CollectionEventsHandler(props);
        this.isItemsDirty = false;
    }


    haveAny(): boolean {
        return this.items.length > 0;
    }

    empty(): boolean {
        return this.items.length <= 0;
    }

    notEmpty(): boolean {
        return !this.empty();
    }

    exists(index: number, field?: string): boolean {
        if (field !== undefined) {
            return this.at(index).exists(field);
        }
        else {
            return !!this.items[index];
        }
    }

    isDirty(): boolean {
        return this.isItemsDirty;
    }

    //region setters

    replace(rows: KeyValueObject[], fireEvents: boolean = true): this {
        this.items = [...rows].map((item) => new CollectionItem(this.itemFormatter.fire(item)));
        if (fireEvents) {
            this.events.fire(CollectionEventEnum.afterEdit, CollectionTriggerEnum.replace);
        }
        return this;
    }

    flush(fireEvents: boolean = true): this {
        return this.replace([], fireEvents);
    }

    editValue(index: number, field: string, value: any): this {
        if (!this.exists(index)) {
            throw new Error(`index(${field}) does not exist`);
        }
        const item = this.at(index);
        this.events.fire(
            CollectionEventEnum.beforeEdit,
            CollectionTriggerEnum.editValue,
            {
                field: field,
                value: value,
                index: index,
                row: item.getItem(true)
            }
        );
        this.isItemsDirty = item.editValue(field, value).isDirty();
        this.events.fire(
            CollectionEventEnum.afterEdit,
            CollectionTriggerEnum.editValue,
            {
                field: field,
                value: value,
                index: index,
                row: item.getItem(true)
            }
        );
        return this;
    }

    changeValueById(id: number, field: string, value: any): this {
        const index = this.getIndexById(id);
        if (index === null) {
            return this;
        }
        return this.editValue(index, field, value);
    }

    prepend(item: KeyValueObject|KeyValueObject[], formatRow: boolean = true): this {
        Arr.toArray(item).forEach(row => {
            if (formatRow) {
                row = this.itemFormatter.fire(row);
            }
            this.items.unshift(new CollectionItem(row));
        });
        this.events.fire(
            CollectionEventEnum.afterEdit,
            CollectionTriggerEnum.prepend,
            {
                row: this.at(0).getItem()
            }
        );

        this.isItemsDirty = true;
        return this;
    }

    add(item: KeyValueObject|KeyValueObject[], formatRow: boolean = false, fireEvents: boolean = true): this {
        Arr.toArray(item).forEach(row => {
            if (formatRow) {
                row = this.itemFormatter.fire(row);
            }
            this.items.push(new CollectionItem(row));
        });
        const count = this.items.length;
        const lastIndex = count - 1;
        if (fireEvents) {
            this.events.fire(
                CollectionEventEnum.afterEdit,
                CollectionTriggerEnum.append,
                {row: this.at(lastIndex).getItem()}
            );
        }
        this.isItemsDirty = true;
        return this;
    }

    autoModify(rows: KeyValueObject[], prepend?: boolean): this {
        rows.forEach(row => {
            if (this.getById(row.ID) === null) {
                if (prepend) {
                    this.prepend(row);
                }
                else {
                    this.add(row);
                }
            }
            else {
                this.setById(row.ID, row, true);
            }
        });
        this.events.fire(CollectionEventEnum.afterEdit, CollectionTriggerEnum.autoModify);
        this.isItemsDirty = true;
        return this;
    }

    remove(index: number): this {
        this.items.splice(index, 1);
        this.events.fire(
            [CollectionEventEnum.afterEdit, CollectionEventEnum.afterDelete],
            CollectionTriggerEnum.remove
        );
        this.isItemsDirty = true;
        return this;
    }

    removeByField(field: string, value: any): this {
        const index = this.getIndexByField(field, value);
        if (index === null) {
            return this;
        }
        return this.remove(index);
    }

    removeById(id: number): this {
        const index = this.getIndexByField('ID', toNumber(id));
        if (index === null) {
            return this;
        }
        return this.remove(index);
    }

    set(index: number, row: KeyValueObject, voidEvents: boolean = false): this {
        this.isItemsDirty = this
            .at(index)
            .replace(this.itemFormatter.fire(row), true)
            .isDirty();
        if (!voidEvents) {
            this.events.fire(
                CollectionEventEnum.afterEdit,
                CollectionTriggerEnum.set,
                {row: this.at(index).getItem()}
            );
        }
        return this;
    }

    setByFieldValue(field: string, value: any, row: KeyValueObject, voidEvents: boolean = false): this {
        const index = this.getIndexByField(field, value, null);
        if (index === null) {
            return this;
        }
        return this.set(index, row, voidEvents);
    }

    setByField(field: string, row: KeyValueObject, voidEvents: boolean = false): this {
        return this.setByFieldValue(field, row[field], row, voidEvents);
    }

    setById(id: number, row: KeyValueObject, voidEvents: boolean = false): this {
        return this.setByFieldValue('ID', toNumber(id), row, voidEvents);
    }

    reOrderByField(field: string, activeValue: any, overValue: any): this {
        const oldIndex = this.getIndexByField(field, activeValue);
        const newIndex = this.getIndexByField(field, overValue);
        if (oldIndex === null || newIndex === null) {
            return this;
        }
        // console.table({
        //     oldIndex: this.get(oldIndex).orderNo,
        //     newIndex: this.get(newIndex).orderNo,
        // });
        this.items = [...arrayMove(this.items, oldIndex, newIndex)];
        this.events.fire(
            [CollectionEventEnum.afterEdit, CollectionEventEnum.afterOrder],
            CollectionTriggerEnum.reOrder,
            {ids: this.getIds()}
        );
        return this;
    }

    //endregion

    //region getters
    at(index: number): CollectionItem {
        return this.items[index];
    }

    atId(id: number): CollectionItem {
        const found = this.items.find(item => item.get('ID') === id);
        if (found === undefined) {
            return new CollectionItem({});
        }
        return found;
    }

    get(index: number, defaultValue: any = null): KeyValueObject {
        if (!this.exists(index)) {
            return defaultValue;
        }
        return this.items[index].getItem();
    }

    getValue(index: number, field: string, defaultValue: any = null): any {
        if (!this.exists(index)) {
            return defaultValue ?? null;
        }
        if (!this.at(index).exists(field)) {
            return defaultValue;
        }
        return this.at(index).get(field);
    }

    getArray(index: number, field: string): any[] {
        return this.getValue(index, field, []);
    }

    first(defaultValue: any = null): KeyValueObject {
        return this.get(0, defaultValue);
    }

    last(defaultValue: any = null): KeyValueObject {
        return this.get(this.items.length - 1, defaultValue);
    }

    findByFieldValue(field: string, value: any, defaultValue: any = null): KeyValueObject|any {
        const found = this.items.find((item) => item.exists(field) && item.get(field) === value);
        if (!found) {
            return defaultValue ?? null;
        }
        return found.getItem();
    }

    findValue<S extends (row: KeyValueObject, index?: number) => boolean, F extends string, A = null>(callback: S, field: F, defaults: A = (null as unknown) as A): any {
        const found = this.find(callback, null);
        return found ? found[field] : defaults;
    }

    getById(id: number|string, defaultValue: any = null): KeyValueObject|any {
        return this.findByFieldValue('ID', id, defaultValue);
    }

    getIndexByField(field: string, value: any, defaultValue: any = null): number|null {
        const index = this.items.findIndex((item) => item.get(field) === value);
        if (index < 0) {
            return defaultValue ?? null;
        }
        return index;
    }

    getIndexById(id: number, defaultValue: any = null): number|null {
        return this.getIndexByField('ID', id, defaultValue);
    }

    getIds(): number[] {
        return this.getFieldValues('ID');
    }

    getSortIds(): string[] {
        return this.map(item => item.ID.toString());
    }

    getDirty(): KeyValueObject[] {
        return [...this.items].filter(item => item.isDirty()).map(item => item.getItem());
    }

    getItems(clone: boolean = false): KeyValueObject[] {
        if (clone) {
            return [...this.items].map(item => {
                return {...item.getItem()};
            });
        }
        return this.items.map(item => {
            return item.getItem();
        });
    }

    count(): number {
        return this.items.length;
    }

    logConsole(name: string = 'colleciton'): void {
        console.log(name, this.getItems());
    }

    //endregion

    //region events
    handleOrderEndById(event: DragEndEvent): void {
        const active = event.active as Active;
        const over = event.over as Over;
        if (active.id !== over.id) {
            this.reOrderByField('ID', toNumber(active.id), toNumber(over.id));
        }
    }

    afterEdit(caller: CollectionEventCallback): void {
        -
            this.after(CollectionEventEnum.afterEdit, caller);
    }

    beforeEdit(caller: CollectionEventCallback): void {
        this.after(CollectionEventEnum.beforeEdit, caller);
    }

    afterOrder(caller: CollectionEventCallback): void {
        this.after(CollectionEventEnum.afterOrder, caller);
    }

    after(events: CollectionEventPropertyType, caller: CollectionEventCallback): void {
        this.events.add(events, caller);
    }


    //endregion

    //region array extenders
    countBy(callback: (row: KeyValueObject, index: number) => any): number {
        return this.getItems().filter((item, index) => callback(item, index)).length;
    }

    distinct(callback: (row: KeyValueObject, index: number) => any): any[] {
        const items = [] as any[];
        this.each((item, index) => {
            const row = item.getItem();
            const value = callback(row, index);
            if (!items.includes(value)) {
                items.push(value);
            }
        });
        return items;
    }

    groupBy(getKey: (row: KeyValueObject, index: number) => string|number): KeyValueValueObject {
        const grouped = {};
        this.each((item, index) => {
            const row = item.getItem();
            const key = getKey(row, index).toString();
            // @ts-ignore
            if (typeof grouped[key] == 'undefined') {
                // @ts-ignore
                grouped[key] = [];
            }
            // @ts-ignore
            grouped[key].push(row);

        });
        return grouped;
    }

    map(callback: (row: KeyValueObject, index: number) => any): any {
        return this.getItems().map((item, index) => callback(item, index));
    }

    transform(callback: (row: KeyValueObject, index: number,) => any, fireEvents: boolean = true): this {
        const newItems = this.map(callback);
        this.replace(newItems, fireEvents);
        return this;
    }

    getFieldValues(field: string, filter?: (row: KeyValueObject, index: number) => boolean): any[] {
        const items = filter ? this.filter(filter) : this.getItems();
        return items.map(item => item[field]);
    }

    some(callback: (row: CollectionItem, index: number) => boolean): boolean {
        return this.items.some((item, index) => callback(item, index));
    }

    includes(value: (row: KeyValueObject, index?: number) => boolean|any): boolean {
        if (typeof value === 'function') {
            const found = this.getItems().find((item, index) => value(item, index));
            return found !== undefined;
        }

        return this.getItems().includes(value);
    }

    find<S extends (row: KeyValueObject, index?: number) => boolean, A = null>(callback: S, defaults: A = (null as unknown) as A): KeyValueObject|A {
        const found = this.getItems().find((item, index) => callback(item, index));
        if (found === undefined) {
            return defaults;
        }
        return found;
    }


    findLast<S extends (row: KeyValueObject, index?: number) => boolean, A = null>(callback: S, defaults: A = (null as unknown) as A): KeyValueObject|A {
        const found = this.getItems().reverse().find((item, index) => callback(item, index));
        if (found === undefined) {
            return defaults;
        }
        return found;
    }

    each(callback: (row: CollectionItem, index: number) => void): void {
        this.items.forEach(callback);
    }

    filter(callback: (row: KeyValueObject, index: number) => boolean): KeyValueObject[] {
        return this.getItems().filter((item, index) => callback(item, index));
    }


    //endregion
}
