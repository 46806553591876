import {APIActionGateway, APIActionsOptions, APILoadActionOptions} from 'services';
import {CommonSuccessResponse, KeyValueObject, QueryParams} from 'types';
import {Collection, CollectionOptions} from 'utils/Collection/Collection';
import {CollectionEventCallback, CollectionEventEnum} from 'utils/Collection/CollectionEventsHandler';
import {CommonAPIHandlerProps} from 'utils/Form';
import StateManager, {StateValue} from 'utils/StateManager';

interface APICollectionStateValue extends StateValue {
    data: KeyValueObject[],
    isLoaded: boolean;
}

export interface APICollectionProps extends CommonAPIHandlerProps, CollectionOptions, APIActionsOptions {

}


export class APICollection extends Collection {
    public api!: APIActionGateway;
    protected state: StateManager<APICollectionStateValue>;


    constructor(props: APICollectionProps) {
        const {
            api,
            afterOrder,
            useState,
            data,
            ...rest
        } = props;
        let realData = data ? [{...data}] : [];
        const state = useState(APICollection.createStateValue(realData));
        super({
            items: state[0].data,
            ...rest
        });

        this.state = new StateManager(state);
        this.api = new APIActionGateway({
            api: api,
            options: rest
        });
    }

    isLoaded(): boolean {
        return this.state.value.isLoaded;
    }

    replace(rows: KeyValueObject[], fireEvents: boolean = true): this {
        super.replace(rows, fireEvents);
        this.state.setData(rows).dispatch('replace');
        return this;
    }

    flush(fireEvents: boolean = true): this {
        super.flush(fireEvents);
        this.refreshDataSate().dispatch('flush');
        return this;
    }

    editValue(index: number, field: string, value: any): this {
        super.editValue(index, field, value);
        this.refreshDataSate().dispatch('editValue');
        return this;
    }

    prepend(item: KeyValueObject|KeyValueObject[], formatRow: boolean = true): this {
        super.prepend(item, formatRow);
        this.refreshDataSate().dispatch('prepend');
        return this;
    }

    add(item: KeyValueObject|KeyValueObject[], formatRow: boolean = false, fireEvents: boolean = true): this {
        super.add(item, formatRow, fireEvents);
        this.refreshDataSate().dispatch('append');
        return this;
    }

    autoModify(rows: KeyValueObject[], prepend?: boolean): this {
        super.autoModify(rows, prepend);
        this.refreshDataSate().dispatch('autoModify');
        return this;
    }

    reOrderByField(field: string, activeValue: any, overValue: any): this {
        super.reOrderByField(field, activeValue, overValue);
        this.api.reOrder(this.getIds()).then(() => {
            this.refreshDataSate().dispatch('reOrderByField');
        });
        return this;
    }

    remove(index: number): this {
        super.remove(index);
        this.refreshDataSate().dispatch('remove');
        return this;
    }

    set(index: number, row: KeyValueObject, voidEvents: boolean = false): this {
        super.set(index, row, voidEvents);
        this.refreshDataSate().dispatch('set');
        return this;
    }

    async load(opts?: APILoadActionOptions, fireEvents: boolean = true): Promise<KeyValueObject[]> {
        return this.api.load(opts, fireEvents).then(response => {
            console.log('APICollection.load', response);
            this.replace(response, true);
            this.refreshDataSate().set({isLoaded: true}).dispatch('load');
            return response;
        });
    }

    async deleteById(id: number, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
        this.removeById(id);
        return this.api.deleteById(id, queryParams);
    }


    afterLoad(caller: CollectionEventCallback): void {
        this.api.events.add(CollectionEventEnum.afterLoad, caller);
    }

    //region HTML events
    deleteRowClick(id: number): () => void {
        return () => {
            return this.deleteById(id).then((res) => {
                this.removeById(id);
                return res;
            });
        };
    }

    toggleVisibilityClick(id: number, bol: boolean, commit: boolean = true): () => void {
        return () => {
            this.changeValueById(id, 'published', bol);
            if (commit) {
                this.api.togglePublish(id, bol);
            }
        };
    }

    //endregion

    //region state
    static createStateValue(items: KeyValueObject[] = []): APICollectionStateValue {
        return StateManager.createStateValue({
            data: items,
            isLoaded: false,
        });

    }

    refreshDataSate() {
        return this.state.setData(this.getItems(true));
    }

    /**
     * get last dispatch event
     */
    get event(): string|null {
        return this.state.event;
    }

    //endregion

}
