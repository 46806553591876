import {APIService} from 'services';
import {QueryParams, SelectValuesResponse} from 'types';

export class UiSelectValuesService extends APIService {

    private getSelectValues(type: string, queryParams?: QueryParams): Promise<SelectValuesResponse[]> {
        return super.get(`/ui/selectListValues/${type}`, queryParams);
    }

    public homepageJobYear(): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('homepageJobYear');
    }

    public homepageTemplates(): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('homepageTemplates');
    }

    public area(): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('area');
    }

    public procurementBlogCategories(): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('procurementBlogCategories');
    }

    public unit(): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('unit');
    }

    public city(areaId: number = 0): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('city', {areaId: areaId});
    }

    public fields(companyId: number = 0): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('fields', {companyId: companyId});
    }

    public companyNames(filterName: string = '', limit: number = 10): Promise<SelectValuesResponse[]> {
        return this.getSelectValues('companyNames', {query: filterName, limit: limit});
    }
}
