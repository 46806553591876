import CloseModal from 'components/icons/CloseModal';
import mediaQueries from 'constants/mediaQueries';
import * as React from 'react';
import ReactModal, {Classes} from 'react-modal';
import styled from 'styled-components';

export interface ReactModalProps extends ReactModal.Props {
    modalClassName?: Classes|string;
    overlayCSS?: string;
    contentCSS?: string;
}

export const ReactModalAdapter: React.FC<ReactModalProps> = ({className, modalClassName, ...props}) => {
    return <ReactModal
        portalClassName={`${className} reactModalPortal`}
        className={modalClassName}
        {...props}
    />;
};

export const ReactModalWrapper = styled(ReactModalAdapter)`
  & .Overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(60, 81, 104, 0.8);
    height: 100%;
    position: fixed;
    overflow: auto;
    padding-bottom: 100px;
    ${props => props.overlayCSS && props.overlayCSS}
  }

  .Modal {
    position: relative;
    width: fit-content;
    display: flex;
    box-shadow: 0 4px 35px rgb(0 0 0 / 7%);
    border-radius: 6px;
    overflow: hidden;
    ${props => props.contentCSS && props.contentCSS}
  }

`;
export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const CloseButton = styled.button`
  display: flex;
  width: fit-content;
  background: transparent;
  border: none;
  position: absolute;
  right: 27px;
  top: 27px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media ${mediaQueries.mobileOrTablet} {
    top: 20px;
    right: 20px;
  }
`;

export const CloseModalIcon = styled(CloseModal)``;

export const Header = styled.div`
  display: flex;
  background-color: #fff;
  padding: 40px 30px;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #3C5168;
  margin-bottom: 0;
`;