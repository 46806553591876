import {APIActionService, CommonAPIActionProps} from 'services';
import {Globals} from 'utils/Globals';

export interface CompanyAPIServiceProps extends CommonAPIActionProps {
}

export class CompanyAPIService extends APIActionService {

    constructor(props: CompanyAPIServiceProps) {
        const {path, ...rest} = props;
        const realPath = path ? `/${path}` : '';
        super({
            path: `crm/company/${Globals.getCompanyId().toString()}${realPath}`,
            ...rest
        });
    }
}