import {APIService} from 'services';
import {ChangePasswordRequest, CommonSuccessResponse, CompanyInfo, CreateLoginRequest, RestorePasswordRequest, SSCheckCompanyResponse} from 'types';

export class AuthAPIService extends APIService {

    constructor() {
        super({
            path: '/ss'
        });
    }

    login(email: string, password: string): Promise<CommonSuccessResponse> {
        return this.post('login', {email: email, password: password});
    }

    checkCompany(hash: string): Promise<SSCheckCompanyResponse> {
        return this.get(`check/${hash}`);
    }

    createLogin(loginInfo: CreateLoginRequest): Promise<SSCheckCompanyResponse> {
        return this.post('createLogin', loginInfo);
    }

    changePassword(data: ChangePasswordRequest): Promise<CommonSuccessResponse> {
        return this.post('changePassword', data);
    }

    forgotPassword(email: string): Promise<CommonSuccessResponse> {
        return this.post('forgotPassword', {email});
    }

    restorePassword(restoreInfo: RestorePasswordRequest): Promise<CommonSuccessResponse> {
        return this.post('createNewPassword', restoreInfo);
    }

    getCompany(): Promise<CompanyInfo> {
        return this.get('company');
    }
}