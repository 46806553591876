import * as React from 'react';
import {Navigate} from 'react-router-dom';
import {TokenService} from 'services';


const PrivateRoute: ({children}: {
    children: React.JSX.Element
}) => (React.JSX.Element) = ({children}) => {
    if (!TokenService.hasUserAttached()) {
        return <div>Not logined</div>
        //return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
};
export default PrivateRoute;
