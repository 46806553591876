import {APIActionService, APILoadActionOptions} from 'services';
import {CommonSuccessResponse, QueryParams} from 'types';
import {Arr} from 'utils/Arr';
import {CollectionEventCallback, CollectionEventEnum, CollectionEventsHandler, CollectionTriggerEnum} from 'utils/Collection';
import {errorModal} from 'utils/errorModal';
import {Globals} from 'utils/Globals';
import {ObjectFormatter, ObjectFormatterPropsType} from 'utils/ObjectFormatter';


export interface APIActionsOptions {
    submitMany?: boolean;
    afterSubmit?: CollectionEventCallback;
    afterLoad?: CollectionEventCallback;

    submitFormatter?: ObjectFormatterPropsType;
    loadFormatter?: ObjectFormatterPropsType;
    submitValidator?: () => boolean;
}

interface APIActionsProps {
    api: APIActionService;
    options: APIActionsOptions;
}

export class APIActionGateway {
    private api: APIActionService;
    public events: CollectionEventsHandler;
    public options: APIActionsOptions;
    private lastError?: string;

    constructor(props: APIActionsProps) {
        this.options = props.options;
        this.api = props.api;
        this.events = new CollectionEventsHandler(this.options);
    }

    async load(options?: APILoadActionOptions, fireEvents: boolean = true): Promise<any> {
        return this.api.load(options).then(response => {
            if (this.options.loadFormatter) {
                return (new ObjectFormatter(this.options.loadFormatter)).fire(response);
            }
            if (fireEvents) {
                this.events.fire(
                    CollectionEventEnum.afterLoad,
                    CollectionTriggerEnum.load,
                    {response: response}
                );
            }
            return response;
        });
    }

    async submit(payload: any, queryParams?: QueryParams, fireEvents: boolean = true): Promise<any> {
        if (this.options.submitValidator) {
            if (!this.options.submitValidator()) {
                return new Promise((resolve, reject) => {
                    Globals.disableLoading();
                    if (this.lastError) {
                        errorModal(this.lastError);
                    }
                    else {
                        errorModal('Salvestamine ebaõnnestus');
                    }
                    //reject("failed");
                });
            }
        }
        if (this.options.submitMany) {
            payload = Arr.toArray(payload);
        }
        if (this.options.submitFormatter) {
            payload = (new ObjectFormatter(this.options.submitFormatter)).fire(payload);
        }
        return this.api.submit(payload, queryParams).then(response => {
            if (fireEvents) {
                this.events.fire(
                    CollectionEventEnum.afterSubmit,
                    CollectionTriggerEnum.submit,
                    {response}
                );
            }
            return response;
        });
    }

    async reOrder(ids: number[]): Promise<CommonSuccessResponse> {
        return this.api.reOrder(ids);
    }

    async deleteById(id: number, queryParams?: QueryParams): Promise<CommonSuccessResponse> {
        return this.api.deleteById(id, queryParams);
    }

    async togglePublish(id: number, published: boolean): Promise<any> {
        return this.api.patch('modify', [{ID: id, patch: {published: published, savedOnly: false}}]);
    }

    setLastError(error: string): this {
        this.lastError = error;
        return this;
    }
}