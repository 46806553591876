import {KeyValueObject} from 'types';
import {CollectionEventEnum, CollectionItem, CollectionTriggerEnum} from 'utils/Collection';


export class CollectionEvent {
    readonly type: CollectionEventEnum;
    readonly trigger: CollectionTriggerEnum;
    private eventData: CollectionItem;

    constructor(
        type: CollectionEventEnum,
        trigger: CollectionTriggerEnum,
        data: KeyValueObject = {}
    ) {
        this.type = type;
        this.trigger = trigger;
        this.eventData = new CollectionItem(data ? data : {});
    }

    is(type: string|string[]) {
        let check = [];
        if (type instanceof Array) {
            check.push(...type);
        }
        else {
            check = [type];
        }
        return check.includes(this.type.toString());
    }


    isTrigger<N extends (keyof CollectionTriggerEnum)|string|CollectionTriggerEnum>(name: N|N[]) {
        let check: string[] = [];
        if (name instanceof Array) {
            check.map(i => i.toString()).push(...name.map(n => n.toString()));
        }
        else {
            check = [name.toString()];
        }
        return check.includes(this.trigger.toString());
    }


    data<D extends KeyValueObject>(): D {
        return this.eventData.getItem() as D;
    }

    row<D extends KeyValueObject>(): D {
        return this.get('row') as D;
    }

    get item(): CollectionItem {
        if (this.has('item')) {
            return this.get('item');
        }
        return new CollectionItem(this.get('row'));
    }

    get<F extends string, D = null>(field: F, defaults: D = (null as unknown) as D): any|D {
        return this.eventData.get(field, defaults);
    }

    has(field: string): boolean {
        return this.eventData.exists(field);
    }


    response<D extends KeyValueObject>(): D {
        return this.eventData.get('response') as D;
    }

    ids(): number[] {
        return this.get('ids');
    }
}
