import * as Styled from 'components/LoadingOverlay/LoadingOverlay.styled';
import Modal from 'components/modals/Modal/Modal';
import {commonLocalization} from 'constants/commonLocalization';
import * as React from 'react';
import {useAxiosLoader} from 'utils/api';
import {gsm} from 'utils/globalStateManager';

interface OwnProps {
    title?: string|null;
}

const LoadingOverlay: React.FC<OwnProps> = (props) => {
    const [isAxiosLoading, progress] = useAxiosLoader();
    const isLoading = isAxiosLoading > 0 || gsm.isLoading();
    const realProgress = (isLoading ? progress : 0) as number;
    const title = props.title ?? commonLocalization.loadingOverlayTitle;
    return (
        <Modal
            isOpen={isLoading}
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => {
                console.log('close');
            }}
            overlayCSSProps={{
                zIndex: 999999
            }}
            contentCSSProps={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                margin: 'auto'
            }}
        >
            <Styled.LoadingWrapper>
                <Styled.LoadingTitle>{title}</Styled.LoadingTitle>
                <Styled.LoadingSpinner>
                    <img src={'/assets/img/loadingGif.gif'} alt={'spinner'}/>
                </Styled.LoadingSpinner>
                {realProgress && (realProgress > 0 && realProgress < 100) ? <Styled.LoadingStatus>{realProgress}%</Styled.LoadingStatus> : null}
            </Styled.LoadingWrapper>
        </Modal>
    );
};

export default LoadingOverlay;
