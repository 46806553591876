import {AxiosResponse} from 'axios';
import {QueryParams, QueryParamsObject} from 'types';
import {APIServiceAxiosRequestConfig, axiosInstance} from 'utils/api';

//TODO check this https://react-query.tanstack.com/ mmaybe this would be better solution
// https://www.youtube.com/watch?v=novnyCaa7To

enum CallEventType {
    before = 'before',
    after = 'after'
}

export interface APIServiceProps {
    path?: string;
    queryParams?: QueryParams;
    config?: APIServiceAxiosRequestConfig;
}

type beforeAction = (api: APIService) => any;
type afterAction = (response: any, api: APIService) => any;

interface CallEvent {
    type: CallEventType;
    actionName: string|null;
    cb: beforeAction|afterAction;
    suspended: boolean;
}

export class APIService {

    private events: CallEvent[];
    private queryParams: QueryParamsObject;
    private path = '';
    private config: APIServiceAxiosRequestConfig;

    constructor(props?: APIServiceProps) {
        this.events = [] as CallEvent[];
        this.queryParams = {} as QueryParamsObject;
        if (props) {
            if (props.path) {
                this.setBasePath(props.path);
            }
            this.queryParams = APIService.toQueryParamsObject(props.queryParams);
        }
        this.config = (props && props.config) ? props.config : {};
    }

    protected setBasePath(path: string): void {
        this.path = path;
    }

    protected getBasePath(): string {
        return this.path;
    }

    setAxiosConfig(config: APIServiceAxiosRequestConfig): this {
        this.config = config;
        return this;
    }

    setConfigValue<K extends keyof APIServiceAxiosRequestConfig>(name: K, value?: APIServiceAxiosRequestConfig[K]): this;
    setConfigValue(name: keyof APIServiceAxiosRequestConfig, value?: any): this {
        this.config[name] = value;
        return this;
    }

    voidLoadingOverlay(): this {
        return this.setConfigValue('voidLoadingOverlay', true);
    }

    getConfig(merge: APIServiceAxiosRequestConfig = {}): APIServiceAxiosRequestConfig {
        return {...this.config, ...merge};
    }

    static createPath(base: string, path: string): string {

        if (path && path[0] === '/') {
            if (path.charAt(path.length - 1) === '/') {
                path = path.substring(0, path.length - 1);
            }
            return path;
        }

        if (base.charAt(base.length - 1) === '/') {
            base = base.substring(0, base.length - 1);
        }
        if (path && path[0] === '.') {
            return base + '/' + path.substring(1);
        }
        return base + (path ? '/' + path : '');
    }

    async get(endpoint: string, queryParams?: QueryParams): Promise<any> {
        return await this.call('get', endpoint, undefined, queryParams);
    }

    async put(endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call('put', endpoint, payload, queryParams);
    }

    async post(endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call('post', endpoint, payload, queryParams);
    }

    async patch(endpoint: string, payload: any, queryParams?: QueryParams): Promise<any> {
        return await this.call('patch', endpoint, payload, queryParams);
    }

    async delete(endpoint: string, queryParams?: QueryParams): Promise<any> {
        return await this.call('delete', endpoint, queryParams);
    }

    static toQueryParamsObject(params: QueryParams): QueryParamsObject {
        let realParams = {} as QueryParamsObject;
        if (!params || typeof params === 'undefined') {
            return realParams;
        }
        if (typeof params == 'string') {
            params.toString().split('&').forEach(p => {
                const sp = p.split('=');
                realParams[sp[0]] = sp[1];
            });

        }
        else {
            realParams = Object.assign(realParams, params);
        }
        return realParams;
    }

    addQueryParam(key: string, value: string) {
        this.queryParams[key] = value;
    }

    private async call(method: string, endpoint: string, payload?: any, queryParams?: QueryParams): Promise<any> {
        let promise: Promise<any>;
        const config = this.getConfig({
            params: {
                ...this.queryParams,
                ...APIService.toQueryParamsObject(queryParams)
            }
        });
        let uri = APIService.createPath(this.path, endpoint);
        if (uri.charAt(uri.length - 1) === '/') {
            uri = uri.substring(0, uri.length - 1);
        }
        if (['get', 'delete', 'head', 'options'].includes(method)) {
            // @ts-ignore
            promise = axiosInstance[method](uri, config);
        }
        else {
            // @ts-ignore
            promise = axiosInstance[method](uri, payload, config);
        }

        //this.callEvents(CallEventType.before, [this]);

        return promise
            .then((response: AxiosResponse<any>) => {
                //this.callEvents(CallEventType.after, actionName, response);
                return response.data;
            });
    }

    private callEvents(type: CallEventType, callbackArguments: any[] = []): this {
        this.events.map(event => {
            if (event.type === type && !event.suspended) {
                // @ts-ignore
                event.cb.apply(null, callbackArguments);
            }
            event.suspended = false;
            return event;
        });
        return this;
    }

    private addEvent(type: CallEventType, callback: beforeAction|afterAction): this {
        //do not add event twice
        if (this.events.find((event) => (event.type === type && event.cb.toString() === callback.toString()))) {
            return this;
        }
        this.events.push({
            type: type,
            cb: callback,
            suspended: false
        } as CallEvent);
        return this;
    }

    suspendBefore(): this {
        this.setEventSuspension(CallEventType.before, true);
        return this;
    }

    continueBefore(): this {
        this.setEventSuspension(CallEventType.before, false);
        return this;
    }

    suspendAfter(): this {
        this.setEventSuspension(CallEventType.after, true);
        return this;
    }

    continueAfter(): this {
        this.setEventSuspension(CallEventType.after, false);
        return this;
    }

    private setEventSuspension(type: CallEventType|null, suspended: boolean): this {
        this.events = this.events.map(event => {
            if (event.type === null || event.type === type) {
                event.suspended = suspended;
            }
            return event;
        });
        return this;
    }

    before(callBefore: beforeAction): this {
        return this.addEvent(CallEventType.before, callBefore);
    }

    after(callAfter: afterAction): this {
        return this.addEvent(CallEventType.after, callAfter);
    }
}
