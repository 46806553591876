import {KeyValueObject} from 'types';
import {Arr} from 'utils/Arr';

interface ObjectFormatterCallback<T extends KeyValueObject> {
    (row: T): T;
}

export type ObjectFormatterPropsType = ObjectFormatterCallback<KeyValueObject>|ObjectFormatterCallback<KeyValueObject>[];

export class ObjectFormatter {
    protected formatters: ObjectFormatterCallback<any>[] = [];

    constructor(formatter?: ObjectFormatterCallback<any>|ObjectFormatterCallback<any>[]) {
        if (formatter) {
            return this.add(formatter);
        }
        return this;
    }

    add(formatter: ObjectFormatterCallback<any>|ObjectFormatterCallback<any>[]): this {
        if (formatter) {
            this.formatters.push(...Arr.toArray(formatter).filter(f => f !== undefined));
        }
        return this;
    }

    fire<D extends KeyValueObject>(data: D|D[]): D {
        const f = this.formatters;
        this.formatters.forEach((formatter: ObjectFormatterCallback<D>) => {
            if (Array.isArray(data)) {
                data = data.map(r => formatter(r));
            }
            else {
                data = formatter(data);
            }
        });
        return data as D;
    }
}

