import {KeyValueObject} from 'types';
import {CollectionEvent} from 'utils/Collection/CollectionEvent';
import {Is} from 'utils/Is';

export enum CollectionEventEnum {
    afterDelete = 'afterDelete',
    afterEdit = 'afterEdit',
    beforeEdit = 'beforeEdit',
    afterOrder = 'afterOrder',
    afterSubmit = 'afterSubmit',
    afterUpdateState = 'afterUpdateState',
    afterLoad = 'afterLoad',
    dirtyChange = 'dirtyChange',
}

export enum CollectionTriggerEnum {
    load = 'load',
    replace = 'replace',
    editValue = 'editValue',
    prepend = 'prepend',
    append = 'append',
    autoModify = 'autoModify',
    remove = 'remove',
    set = 'set',
    reOrder = 'reOrder',
    setIsDirty = 'setIsDirty',
    updateState = 'updateState',
    submit = 'submit',
}

export interface CollectionEventCallback {
    (event: CollectionEvent): any;
}

export type CollectionEventPropertyType = CollectionEventEnum|string|CollectionEventEnum[]|string[];


interface EventItem {
    type: CollectionEventEnum;
    cb: CollectionEventCallback;
    suspended: boolean;
}

export class CollectionEventsHandler {
    private events: EventItem[];

    constructor(events?: (KeyValueObject|undefined)[]|KeyValueObject) {
        this.events = [] as EventItem[];
        if (events) {
            // @ts-ignore
            this.addMultiple(Is.array(events) ? events : [events]);
        }
    }

    fire(eventType: CollectionEventPropertyType, trigger: CollectionTriggerEnum, data?: KeyValueObject): this {
        const filterTypes = Is.array(eventType) ? eventType : [eventType];
        this.events
            .filter((event: EventItem) => filterTypes.includes(event.type))
            .forEach(event => event.cb(
                new CollectionEvent(
                    event.type,
                    trigger,
                    data
                )
            ));
        return this;
    }

    add(events: CollectionEventPropertyType, callback: CollectionEventCallback): this {
        events = Array.isArray(events) ? events : [events];
        events.forEach(event => {
            this.events.push({
                type: event,
                cb: callback,
                suspended: false
            } as EventItem);
        });
        return this;
    }

    private addMultiple(events: KeyValueObject[]): this {
        const allEvents = Object.values(CollectionEventEnum).map(item => item.toString());
        events.filter(item => item !== undefined && Is.object(item))
            .filter(event => Object.keys(event).some((name) => allEvents.includes(name)))
            .forEach((event: KeyValueObject) => {
                allEvents.forEach(eventName => {
                    if (event[eventName]) {
                        this.add(eventName, event[eventName]);
                    }
                });
            });
        return this;
    }
}

