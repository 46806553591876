import {PageInfoResponse} from 'components/Layouts/types';
import {APIService, UiSelectValuesService} from 'services';

class UiService extends APIService {
    public selectValues: UiSelectValuesService;

    public constructor() {
        super();
        this.selectValues = new UiSelectValuesService();
    }

    getMenuAndInfoPage(slug: string): Promise<PageInfoResponse> {
        return this.get(`/ui/page${slug}`);
    }
}

export const uiService = new UiService();