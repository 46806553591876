import {commonLocalization} from 'constants/commonLocalization';
import {gsm} from "utils/globalStateManager";

export const errorModal = (error: any, title?: any) => {
    console.log('error', error);
    const setError = (error?: any, title?: string) => {
        let errorContent = '';
        let errorTitle = commonLocalization.errorTitle;

        if (typeof error === 'string' && typeof title === 'string') {
            errorContent = error;
            errorTitle = title;
        }
        else if (typeof error === 'string') {
            errorContent = error;
        }
        else if (error.response?.status === 500) {
            errorContent = commonLocalization.serverError;
        }
        else if (error.response.data) {
            const data = error.response.data;
            if (data.modal) {
                errorContent = data.modal.content;
                errorTitle = data.modal.title;
            }
            else if (data.error && !data.message) {
                errorContent = data.error;
            }
            else if (!data.error && data.message) {
                errorContent = data.message;
            }
            else {
                errorContent = data.message;
                errorTitle = data.error;
            }
        }
        gsm.setError({
            state: true,
            title: errorTitle,
            content: errorContent
        });
    };

    if (typeof error === 'string' && typeof title === 'string') {
        setError(error, title);
    }
    else if (typeof error === 'string') {
        setError(error);
    }
    else if (error.response?.status === 500) {
        setError(commonLocalization.serverError);
    }
    else if (error.response.data) {
        const data = error.response.data;
        if (data.modal) {
            setError(data.modal.content, data.modal.title);
        }
        else if (data.error && !data.message) {
            setError(data.error);
        }
        else if (!data.error && data.message) {
            setError(data.message);
        }
        else {
            setError(data.message, data.error);
        }
    }
    else {
        setError();
    }
};
